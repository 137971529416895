const menuRightReducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'MENU_RIGHT_CLOSE':
            return false;
        case 'MENU_RIGHT_OPEN':
            return true;
        default:
            return isOpen;
    }

}
export default menuRightReducer;