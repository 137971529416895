import {
  Box,
  CircularProgress,
  Typography
} from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { ThemeProvider, createStyled } from "@mui/system";

import { useEffect, useState, useMemo } from "react";
import { styled, createTheme } from '@mui/material/styles';



const useStylesCountDown = createStyled(Box)(({ theme }) => ({
  container: {
    position: "relative",
    width: "200px",
    height: "auto",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  root: {
    position: "relative"
  },
  bottom: {
    color: "#b2b2b2",
    position: "relative",
  },
  top: {
    animationDuration: "100ms",
    position: "absolute",
    left: 0
  },
  circle: {
    strokeLinecap: "round"
  },
  text: {
    fontWeight: "bold",
    fontSize: "1.35em",
    marginTop: "1em"
  }
}));

const BorderLinearProgress = styled(CircularProgress)(({ theme }) => ({
  color: "#b2b2b2",
  position: "relative",
}));

const theme = createTheme({
  typography: {
    fontFamily: [
      'Nimbus Sans Bold',
    ].join(','),
  },
});


const CountDownTimer = (props) => {


  //const classes = useStylesCountDown();
  const { duration, colors = [], colorValues = [], onComplete } = props;

  const [timeDuration, setTimeDuration] = useState(duration);
  const [countdownText, setCountdownText] = useState();
  const [countdownPercentage, setCountdownPercentage] = useState(100);
  const [countdownColor, setCountdownColor] = useState("#004082");

  useEffect(() => {
    let intervalId = setInterval(() => {
      setTimeDuration((prev) => {
        const newTimeDuration = prev - 1;
        const percentage = Math.ceil((newTimeDuration / timeDuration) * 100);
        setCountdownPercentage(percentage);

        if (newTimeDuration === 0) {
          clearInterval(intervalId);
          intervalId = null;
          onComplete();
        }

        return newTimeDuration;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, []);

  useEffect(() => {
    const minutes = Math.floor(timeDuration / 60);
    const seconds = timeDuration % 60;
    setCountdownText(`${minutes}:${seconds < 10 ? "0" + seconds : seconds}`);
  }, [timeDuration]);

  useEffect(() => {
    for (let i = 0; i < colorValues.length; i++) {
      const item = colorValues[i];
      if (timeDuration === item) {
        setCountdownColor(colors[i]);
        break;
      }
    }
  }, [timeDuration]);

  return (

    <Box
      sx={{
        position: "relative",
        width: "200px",
        height: "auto",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: '700',
        fontFamily: 'Nimbus Sans Bold',
        fontStyle: 'normal',
      }}>
      {/*  
        <CircularProgress
          variant="determinate"
      
          size={80}
          thickness={4}
          value={100}
          sx={{ position: 'relative' }}
        />
        <CircularProgress
          variant="determinate"
          size={80}
          thickness={4}
          value={countdownPercentage}
          style={{
            transform: "scaleX(-1) rotate(-90deg)",
            color: countdownColor
          }}
          sx={{ position: 'absolute', top: 0 }}
        />
        */}
      <Typography variant="h2" gutterBottom sx={{
        fontFamily: 'Nimbus Sans Bold',
        fontStyle: 'normal',
        color: '#3e119f'
      }}>{countdownText}</Typography>
    </Box>



  );
};

export default CountDownTimer;
