import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AddTutorial from "./Components/qrcode.component";
import Tutorial from "./Components/tutorial.component";
import TutorialsList from "./Components/tutorials-list.component";

import TextToSpeechComponent from "./Components/ClientMenu/1_TextToSpeech/TextToSpeechComponent";

import Logo from "./Img/android-chrome-512x512.png";

import { runARMode } from "./Redux/actions";

import { connect } from "react-redux";


import TextTospeechService from "./services/text-tospeech.service";

function Starter (props) {

  let name =(params) => {
    this.props.runARMode();
    console.log("ARMode");
    console.log(this.props.ARModeProp);
  }


  
    return (
      <>
        <div className="bg-dark">
          <nav className="navbar navbar-light bg-dark">
            <div className="container" onClick={name}>
              <a className="navbar-brand mx-auto" href="#">
                <img
                  src={Logo}
                  alt=""
                  className="rounded mx-auto d-block w-50 p-3"
                />
              </a>
            </div>
          </nav>
          <div className="container mt-3">
            <TutorialsList />
            <TextToSpeechComponent/>
          </div>
        </div>
  
            
      </>

    );
  }


//export default Admin;

const mapStateToProps = (state) => {
  //console.log(state.settingsLeft)
  return {
    ARModeProp: state.ARMode,
  };
};

const mapDispatchToProps = () => {
  return {
    runARMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(Starter);
