import * as React from 'react';
import {  useNavigate } from "react-router-dom";
import { pink, green } from '@mui/material/colors';
import { useDispatch, useSelector } from "react-redux";
import CountDownTimer from '../../CountDownTimer';

import { Grid, Paper, Box, Stack } from '@mui/material/';
import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import EmailIcon from '@mui/icons-material/Email';
import { Typography } from '@mui/material';
import East from '@mui/icons-material/East'
import { FaLessThan } from 'react-icons/fa';

export default function Icons() {
  const authState = useSelector((state) => state.authState);
  const navigate = useNavigate();
  const [user, setUser] = React.useState(authState || JSON.parse(localStorage.getItem("profile")));
  const [changeColor, setChangeColor] = React.useState(false ||user?.result?.isEmailVerificated)
  console.log(changeColor)

  const handleOnComplete = () => {
    console.log("Completed");
    navigate("/");
    console.log(" navigate("/") Icons")
  };

  React.useEffect(() => {


    if (authState.authData?.result.isEmailVerificated) setChangeColor(true);
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [authState]);

  return (

    <Grid container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}>
      {changeColor ?
        <Grid item >
          <Typography align="center">
            <EmailIcon sx={{ fontSize: 100, color: green[500] }}>add_circle</EmailIcon>
          </Typography>

          <Typography variant="h6" align="center">
            Email confirmed
          </Typography>

        </Grid> :
        <Grid item >
          <EmailIcon sx={{ fontSize: 100, color: pink[500] }}></EmailIcon>
          <East sx={{ fontSize: 100, color: pink[500] }} />
          <Typography variant="h6" align="center">
            Check your email
          </Typography>
          <Box sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 200,
            flexGrow: 1,
          }}>
            <Typography variant="body">
              To confirm your email address, follow the link in the email we just sent to <Box sx={{ fontStyle: 'italic' }}>( user email from previous page here )</Box>
              <CountDownTimer
                duration={300}
                colors={["#ff9248", "#a20000"]}
                colorValues={[20, 10]}
                onComplete={handleOnComplete}
              />
            </Typography>
          </Box>

        </Grid>
      }</Grid>



  );
}