import { createStyled } from '@mui/system';

export default createStyled((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    textAlign: "center"
  },
  logo: {
    maxWidth: 40,
    marginRight: '10px'
  },
}));
