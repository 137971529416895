const menuLeftReducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'MENU_LEFT_CLOSE':
            console.log('MENU_LEFT_CLOSE');
            return false;
        case 'MENU_LEFT_OPEN':
            console.log('MENU_LEFT_OPEN');
            return true;
        default:
            return isOpen;
    }

}
export default menuLeftReducer;