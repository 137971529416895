
import React, { useEffect } from 'react';
import Box from "@mui/material/Box";

export default function AuthReCaptcha() {

  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      console.log("Captcha ready")
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    console.log(e)
  }
  useEffect(() => {
    // Add reCaptcha
    //console.log(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js`
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  }, [])

  return (

    <div className="g-recaptcha" data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      {/*<button className="g-recaptcha"
      data-sitekey="6Lf9zwIoAAAAAGdpcb4OTIRwJlcPV5GEKyeRBFgp"
      data-callback='onSubmit'
      data-action='submit'>Submit</button>
  */}
    </div>
  )
}