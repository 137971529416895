const voiceUrlReducer = (isOpen = false, action) => {
  switch (action.type) {
    case "VOICE_URL_REQUESTED":
      isOpen = false;
      console.log("VOICE_URL_REQUESTED");
      return { isOpen, url: action.payload };
    case "VOICE_URL_RECEIVED":
      isOpen = true;
      console.log("VOICE_URL_RECEIVED");
      console.log(action)
      return { isOpen, url: action.payload };
    default:
      return isOpen;
  }
};
export default voiceUrlReducer;
