import axios from 'axios';

const protocol = process.env.NODE_ENV === "development" ? "https" : "https";
const API = axios.create({ baseURL: `${protocol}://${process.env.NODE_ENV === "development" ? "brandbuddyar.com" : "brandbuddyar.com"}:8080` });


API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});


export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPlans = () => API.get('/subscription/all-subscription-plans')
export const fetchPlanPaymentLink = (payload) => API.post('subscription/create-payment-link', payload)
export const fetchPostsByCreator = (name) => API.get(`/posts/creator?name=${name}`);
export const fetchPostsBySearch = (searchQuery) => API.get(`/posts/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const comment = (value, id) => API.post(`/posts/${id}/commentPost`, { value });
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const checkEmailExist=(formData) => API.post('/user/checkemail',formData)

export const verfiyOpt = (formData) => API.post('/user/verify',formData)
export const resendOTP = (formData) => API.post('/user/resendotp', formData);

export const experiencePut = (formData) => API.post('/experiences/experienceput', formData);
export const experienceGet = (formData) => API.post('/experiences/experienceget', formData);
export const createPayment = (axiosConfig) => API.post('/create-checkout-session', axiosConfig.body, axiosConfig.headers).then((res) => {
  console.log("RESPONSE RECEIVED: ", res);
  return res
})
  .catch((err) => {
    console.log("AXIOS ERROR: ", err);
  });


  export const textToSpeechRequest = (formData) => API.post('/text-to-speech', formData.body,formData.headers ).then((res) => {
    console.log("RESPONSE RECEIVED: ", res);
    return res
  })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    });
