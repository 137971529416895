export const menuClose = () => {
    return {
        type: 'MENU_CLOSE'
    }
}
export const menuOpen = () => {
    return {
        type: 'MENU_OPEN'
    }
}
export const menuRightClose = () => {
    return {
        type: 'MENU_RIGHT_CLOSE'
    }
}
export const menuRightOpen = () => {
    return {
        type: 'MENU_RIGHT_OPEN'
    }
}
export const menuLeftClose = () => {
    return {
        type: 'MENU_LEFT_CLOSE'
    }
}
export const menuLeftOpen = () => {
    return {
        type: 'MENU_LEFT_OPEN'
    }
}
export const menuShOpen = () => {
    return {
        type: 'MENU_SHOPEN'
    }
}
export const menuShClose = () => {
    return {
        type: 'MENU_SHCLOSE'
    }
}
export const greetingAudioOn = () => {
    return {
        type: 'GREETING_AUDIO_ON'
    }
}
export const greetingAudioOff = () => {
    return {
        type: 'GREETING_AUDIO_OFF'
    }
}
export const greetingAudioFinished = () => {
    return {
        type: 'GREETING_AUDIO_FINISHED'
    }
}
export const buttonAugieVisible = () => {
    return {
        type: 'BUTTON_AUGIE_VISIBLE'
    }
}
export const buttonAugieHide = () => {
    return {
        type: 'BUTTON_AUGIE_HIDE'
    }
}
export const augieVisible = () => {
    return {
        type: 'AUGIE_VISIBLE'
    }
}
export const augieHide = () => {
    return {
        type: 'AUGIE_HIDE'
    }
}
export const menuUrlClose = () => {
    return {
        type: 'VOICE_URL_REQUESTED'
    }
}
export const menuUrlOpen = () => {
    return {
        type: 'VOICE_URL_RECEIVED'
    }
}

export const runARMode = () => {
    return {
        type: 'RUN_AR_MODE'
    }
}