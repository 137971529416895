import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import AddTutorial from "./add-tutorial.component";

import { connect } from "react-redux";
import { runARMode } from "../Redux/actions";
 function TutorialsList(props) {
  const [tutorialsState, setTutorialsState] = React.useState({
    tutorials: [],
    currentTutorial: null,
    currentIndex: -1,
    searchTitle: "",
  });

  React.useEffect(() => {
    retrieveTutorials();
  }, []);

  function onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    setTutorialsState({ ...tutorialsState, searchTitle: searchTitle });
  }

  function retrieveTutorials() {
    TutorialDataService.getAll()
      .then((response) => {
        setTutorialsState({ ...tutorialsState, tutorials: response.data });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function refreshList() {
    retrieveTutorials();
    setTutorialsState({
      ...tutorialsState,
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  function setActiveTutorial(tutorial, index) {
    console.log(tutorials);
    setTutorialsState({
      ...tutorialsState,
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  function removeAllTutorials() {
    TutorialDataService.deleteAll()
      .then((response) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function searchTitle2() {
    setTutorialsState({
      ...tutorialsState,
      currentTutorial: null,
      currentIndex: -1,
    });

    TutorialDataService.findByTitle(tutorialsState.searchTitle)
      .then((response) => {
        setTutorialsState({
          ...tutorialsState,
          tutorials: response.data,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  let { tutorials, currentTutorial, currentIndex } = tutorialsState;

  return (
    <>
      <AddTutorial />
      <br />
      <div className="list row">
        {/*
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by id"
              value={tutorialsState.searchTitle || ""}
              onChange={onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={searchTitle2}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        
        <div className="col-md-6">
          <h4>Experience List</h4>

          <ul className="list-group ">
            {tutorials &&
              tutorials.map((tutorial, index) => (
                <li
                  className={
                    "list-group-item list-group-item-light" +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActiveTutorial(tutorial, index)}
                  key={index}
                >
                    <a href={window.location.href.replace("admin","") + tutorial.description}>{window.location.href.replace("admin","")+ tutorial.description}</a>
                </li>
              ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={removeAllTutorials}
          >
            Remove All
          </button>
        </div>
        */}
        <div className="col-md-6">
          {currentTutorial ? (
            <div>
              <h4>Tutorial</h4>
              <div>
                <label>
                  <strong>Title:</strong>
                </label>{" "}
                {currentTutorial.title}
              </div>
              <div>
                <label>
                  <strong>Description:</strong>
                </label>{" "}
                {currentTutorial.description}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentTutorial.published ? "Published" : "Pending"}
              </div>

              <Link
                to={"/tutorials/" + currentTutorial.id}
                className="badge badge-warning"
              >
                Edit
              </Link>
            </div>
          ) : (
            <div>
              <br />
              {/*<p>Please click on a Tutorial...</p>*/}
            </div>
          )}
        </div>
        
      </div>
    </>
  );
}




const mapStateToProps = (state) => {
  //console.log(state.settingsLeft)
  return {
    ARModeProp: state.ARMode,
    menuUrlProp: state.voiceUrl,
  };
};

const mapDispatchToProps = () => {
  return {
    runARMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(TutorialsList);

