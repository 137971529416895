import React, { Component } from "react";
import ReactDOM from "react-dom";
import QRCode from "qrcode.react";
import TutorialDataService from "../services/tutorial.service";

import { connect } from "react-redux";
import { runARMode } from "../Redux/actions";

class AddTutorial extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeSalesPersonName = this.onChangeSalesPersonName.bind(this);
    this.onChangeClientCompanyName = this.onChangeClientCompanyName.bind(this);
    this.onChangeClienContactName = this.onChangeClienContactName.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeSalesDeckUrl = this.onChangeSalesDeckUrl.bind(this);
    this.onChangeIdVoice = this.onChangeIdVoice.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this);
    this.downloadQRCode = this.downloadQRCode.bind(this);

    this.state = {
      id: null,
      title: "",
      description: "",
      published: false,
      submitted: false,
      salespersonname: "",
      clientcompanyname: "",
      cliencontactname: "",
      country: "",
      salesdeckurl: "",
      idvoice: this.props.menuUrlProp.url || "test",
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
    /*
    this.setState({...this.state,
      idvoice: this.props.menuUrlProp.url,
    });

    console.log(this.props.menuUrlProp.url);
    console.log(this.state.idvoice);
    */
  }

  onChangeDescription(e) {
    this.setState({
      description: e,
    });
  }

  onChangeSalesPersonName(e) {
    this.setState({
      ...this.state,
      salespersonname: e.target.value,
      idvoice: this.props.menuUrlProp.url,
    });

    console.log(this.props.menuUrlProp.url);
    console.log(this.state.idvoice);
  }
  onChangeClientCompanyName(e) {
    this.setState({
      clientcompanyname: e.target.value,
    });
  }
  onChangeClienContactName(e) {
    this.setState({
      cliencontactname: e.target.value,
    });
  }
  onChangeCountry(e) {
    this.setState({
      country: e.target.value,
    });
  }
  onChangeSalesDeckUrl(e) {
    this.setState({
      salesdeckurl: e.target.value,
    });
  }

  onChangeIdVoice(e) {
    this.setState({ ...this.state, idvoice: this.props.menuUrlProp });
  }

  saveTutorial() {
    this.onChangeIdVoice();
    var data = {
      //title: this.state.title,
      description: this.state.description,
      clientcompanyname: this.state.clientcompanyname,
      salespersonname: this.state.salespersonname,
      cliencontactname: this.state.cliencontactname,
      country: this.state.country,
      salesdeckurl: this.state.salesdeckurl,
      idvoice: this.state.idvoice,
    };
    /*
    TutorialDataService.create(data)
      .then((response) => {
        this.setState({
          id: response.data.id,
          title: response.data.title,
          description: response.data.description,
          published: response.data.published,
          salespersonname: response.data.salespersonname,
          clientcompanyname: response.data.clientcompanyname,
          cliencontactname: response.data.cliencontactname,
          country: response.data.country,
          salesdeckurl: response.data.salesdeckurl,
          idvoice: response.data.idvoice,
          submitted: true,
        });
         console.log(response.data.description);
         this.onChangeDescription(response.data.description)
      })
      .catch((e) => {
        console.log(e);
      });
      */
  }

  newTutorial() {
    this.setState({
      id: null,
      title: "",
      description: "",
      published: false,
      salespersonname: "",
      clientcompanyname: "",
      cliencontactname: "",
      country: "",
      salesdeckurl: "",
      idvoice: this.props.menuUrlProp,
      submitted: false,
    });
  }

  downloadQRCode() {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${this.state.salesdeckurl}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  render() {
    return (
      <div>
          <div>
            {/*
            <div className="Heading-discription">
              <h2>AUGIE SALES GENERATOR</h2>
            </div>

            
            <div className="mb-3 row">
              <label
                htmlFor="title"
                className="col-sm-4 col-form-label text-right text-light"
              >
                  TITLE
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  required
                  value={this.state.title}
                  onChange={this.onChangeTitle}
                  name="title"
                />
              </div>
            </div>

            <div className="mb-3 row">
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-right text-light"
              >
                DESCRIPTION
              </label>

              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  required
                  value={this.state.description}
                  onChange={this.onChangeDescription}
                  name="description"
                />
              </div>
            </div>
            */}

            <div className="mb-3 row">
{/*
              <label
                htmlFor="title"
                className="col-sm-4 col-form-label text-right text-light"
              >
                SALES PERSON NAME
              </label>
              */}
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  required
                  value={this.state.salespersonname}
                  onChange={this.onChangeSalesPersonName}
                  name="title"
                />
              </div>
            </div>

            <div className="mb-3 row">
              {/*
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-right text-light"
              >
                CLIENT COMPANY NAME
              </label>
            */}
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  required
                  value={this.state.clientcompanyname}
                  onChange={this.onChangeClientCompanyName}
                  name="description"
                />
              </div>
            </div>

            <div className="mb-3 row">
              {/*
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-right text-light small "
              >
                CLIENT CONTACT NAME
              </label>
            */}
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  required
                  value={this.state.cliencontactname}
                  onChange={this.onChangeClienContactName}
                  name="description"
                />
              </div>
            </div>

            <div className="mb-3 row">
              {/*
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-right text-light"
              >
                COUNTRY
              </label>
            */}
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  required
                  value={this.state.country}
                  onChange={this.onChangeCountry}
                  name="description"
                />
              </div>
            </div>
            {/*
            <div className="mb-3 row">
              <label
                htmlFor="description"
                className="col-sm-4 col-form-label text-right text-light"
              >
                <p className="text-end">SALES DECK URL</p>
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  required
                  value={this.state.salesdeckurl}
                  onChange={this.onChangeSalesDeckUrl}
                  name="description"
                />
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col text-center">
                <button
                  onClick={this.saveTutorial}
                  className="btn btn-success mx-auto"
                >
                  GENERATE SALES LINK
                </button>
              </div>
              <div className="col text-center">
                <button
                  onClick={this.saveTutorial}
                  className="btn btn-success mx-auto"
                >
                  GENERATE CHASE LINK
                </button>
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col text-center">
                <div className="Generated-qrcode">
                  <div className="Generated-qrcode-box">
                    <QRCode
                      id="qr-gen"
                      value={window.location.href.replace("admin","") + this.state.description}
                      size={250}
                      level={"H"}
                      includeMargin={true}
                    />
                  </div>
                  <div className="Generated-qrcode-copy">
                    <span
                      className="Generated-copy-text"
                      onClick={this.downloadQRCode}
                    >
                      QR CODE COPY
                    </span>
                  </div>
                </div>
              </div>
              <div className="col text-center">
                <div className="Generated-link">
                  <div className="Generated-link-box">
                    <a href={window.location.href.replace("admin","") + this.state.description}>{window.location.href.replace("admin","")+ this.state.description}</a>
                  </div>
                  <div className="Generated-link-copy">
                    <span className="Generated-copy-text">
                      EXPERIENCE LINK COPY
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="Generated-codes"></div>*/}
          </div>
        

        {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <button className="btn btn-success" onClick={this.newTutorial}>
              Add
            </button>
          </div>
        ) : (null
)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state.settingsLeft)
  return {
    ARModeProp: state.ARMode,
    menuUrlProp: state.voiceUrl,
  };
};

const mapDispatchToProps = () => {
  return {
    runARMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(AddTutorial);
