import * as actionType from "../constants/actionTypes";

const experienceReducer = (state = { experienceData: null }, action) => {
  switch (action.type) {
    case actionType.EXPERIENCE_PUT:
      //localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
      //console.log(action.data);
      return {
        ...state,
        experienceData: action.data,
        loading: false,
        errors: null,
      };
    //case actionType.LOGOUT:
    //localStorage.clear();
    case actionType.EXPERIENCE_GET:
      //localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
      //console.log(action.data);
      return {
        ...state,
        experienceResponseData: action.data,
        loading: false,
        errors: null,
      };
    //return { ...state, authData: null, loading: false, errors: null };
    default:
      return state;
  }
};

export default experienceReducer;
