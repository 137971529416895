import { AvatarCreator, AvatarCreatorConfig, AvatarExportedEvent } from '@readyplayerme/react-avatar-creator';
import { receiveMessage } from '../../../Components/ARScene/avatar/avatar-instantiate'
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_EDIT } from "../../../Redux/constants/actionTypes";

const config: AvatarCreatorConfig = {
  clearCache: true,
  bodyType: 'fullbody',
  quickStart: false,
  language: 'en',
};

const style = { width: '100%', height: '100vh', border: 'none' };

export default function AvatarCreatorApp({ refToAscene }: any) {
  const customEditData = useSelector((state: any) => state.customEditState.customEditData);
  const dispatch = useDispatch();
  const [recipientDetails, setRecipientDetails] = React.useState({
    RPM_url: customEditData.RPM_url,

  });
  const handleOnAvatarExported = (event: AvatarExportedEvent) => {
    console.log(`Avatar URL is: ${event.data.url}`);
    setRecipientDetails((prevState: any) => {
      return { ...prevState, RPM_url: event.data.url };
    });
  };
  //window.addEventListener('message', (e)=>receiveMessage(e,refToAscene), false)
  /*
    React.useEffect(()=>{
      console.log(refToAscene.current.contentWindow.document.getElementById('aScene'))
    },[refToAscene])
    */

  React.useEffect(() => {
    dispatch({
      type: CUSTOM_EDIT, data: {
        ...customEditData,
        RPM_url: recipientDetails.RPM_url,
      }
    });
  },
    [recipientDetails])
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AvatarCreator subdomain="bbar" config={config} style={style} onAvatarExported={handleOnAvatarExported} />
    </div>
  );
}