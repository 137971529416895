import { VERIFY_OPT_REQUEST, VERIFY_OPT_SUCCESS, VERIFY_OPT_FAILURE, RESEND_OTP_REQUEST, RESEND_OTP_SUCCESS, RESEND_OTP_FAILURE, VERIFY_OPT_STATUS } from '../constants/actionTypes';

const initialState = {
    verifyOptLoading: false,
    verifyOptError: null, // Change to null to distinguish from an empty string
    verifyOptMessage: '',
    resendOTPLoading: false,
    resendOTPError: '',
    resendOTPMessage: '',
    verifyOtpStatus: null,
};
  
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_OPT_REQUEST:
            return { ...state, verifyOptLoading: true, verifyOptError: null }; // Set error to null
        case VERIFY_OPT_SUCCESS:
            return { ...state, verifyOptLoading: false, verifyOptMessage: action.payload, verifyOtpStatus: 'success' };
        case VERIFY_OPT_FAILURE:
            return { ...state, verifyOptLoading: false, verifyOptError: action.payload.error }; // Access error from payload
        // Other cases for RESEND_OTP actions...
        case VERIFY_OPT_STATUS: 
            return { ...state, verifyOtpStatus: action.payload };
  
        default:
            return state;
    }
};

export default userReducer;
