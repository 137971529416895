const augieReducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'AUGIE_HIDE':
            console.log('AUGIE_HIDE');
            return false;
        case 'AUGIE_VISIBLE':
            console.log('AUGIE_VISIBLE');
            return true;
        default:
            return isOpen;
    }

}
export default augieReducer;