import { useCallback, useState, useEffect, createRef } from 'react';
import { Box, Paper, Typography, IconButton, Grid, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_EDIT } from "../../../Redux/constants/actionTypes";
import { experienceput } from "../../../Redux/actions/experience";


function DragDropFileUpload({ onFileUpload }) {
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadError, setUploadError] = useState(null)
  const fileInput = createRef();

  const customEditData = useSelector((state) => state.customEditState.customEditData);

  const [recipientDetails, setRecipientDetails] = useState({
    experiencePhoto: customEditData.experiencePhoto,
  });

  const dispatch = useDispatch();

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files;
      if (files && files[0]) {
        handleFileChange(files[0]);
      }
    },
    []
  );

  const handleFileChange = (file) => {
    setLoading(true);
    //onFileUpload(file); 
    const reader = new FileReader();
    reader.onloadend = () => {
      setLoading(false);
      setImagePreview(reader.result);
      console.log(reader.result)
      onFileUpload(reader.result);
      setRecipientDetails((prevState) => {
        return { ...prevState, experiencePhoto: file };
      });
    };
    reader.readAsDataURL(file);
  };
 

  useEffect(() => {
    dispatch({ type: CUSTOM_EDIT, data: {...customEditData, experiencePhoto: recipientDetails.experiencePhoto }});
  },
    [recipientDetails])

  const handleChange = useCallback(
    (event) => {
      const files = event.target.files;
      if (files && files[0]) {
        if (files[0].size > 1024 * 1024 * 1) {
          setUploadError('File size exceeds 1MB')
          return;
        }
        setUploadError(null)
        handleFileChange(files[0]);
      }
    },
    []
  );

  return (
    <Box>
      <Paper
        variant="outlined"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
          padding: 20,
          textAlign: 'center',
          cursor: 'pointer',
          background: dragOver ? '#eee' : '#fafafa',
          position: 'relative',
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          name="experiencePhoto"
          multiple
          type="file"
          onChange={handleChange}
          
        />
        <label htmlFor="raised-button-file">
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton color="primary" aria-label="upload picture" component="span">
              <CloudUploadIcon style={{ fontSize: 60 }} />
            </IconButton>
            <Typography>Drag and drop files here or click to select files</Typography>
          </Box>
        </label>
        {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
        {loading && (
          <CircularProgress
            size={24}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Paper>
      {imagePreview && (
        <Grid container justifyContent="center" style={{ marginTop: 16 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component="img"
              src={imagePreview}
              alt="Image Preview"
              sx={{ width: '100%', height: 'auto' }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default DragDropFileUpload;