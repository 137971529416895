import React, { Component } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, ThemeProvider, createTheme, Container } from "@mui/system";

//import "bootstrap/dist/css/bootstrap.min.css";
//import "./App.css";

import AddTutorial from "./DeliveryForm/GenerateQRComponent/GenerateQRComponent";
import Tutorial from "./tutorial.component";
import TutorialsList from "./tutorials-list.component";

import TextToSpeechComponent from "./ClientMenu/1_TextToSpeech/TextToSpeechComponent";

import { runARMode } from "../Redux/actions";

import { connect } from "react-redux";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
    },
  },
});

//import TextTospeechService from "./services/text-tospeech.service";

function Admin(props) {
  let name = (params) => {
    this.props.runARMode();
    console.log("ARMode");
    console.log(this.props.ARModeProp);
  };

  return (
    <>
      <AddTutorial />
      <TextToSpeechComponent />
    </>
  );
}

//export default Admin;

const mapStateToProps = (state) => {
  //console.log(state.settingsLeft)
  return {
    ARModeProp: state.ARMode,
  };
};

const mapDispatchToProps = () => {
  return {
    runARMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(Admin);
