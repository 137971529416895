import React, { useState, useEffect } from "react";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";

import "./App.css";
import { Box } from "@mui/system";
import * as api from "../../../api/index";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe("pk_test_51Npq6DGEdgJ5CVQ7NmSKgQUWhJRmNewzbxJzO6Km2bSgXowJKk0vjvPcKUmwTTHjSehNdGWctMikK02QFLGVbamp00pKXTOJDE");




const CheckoutForm = (props) => {
  const price = props.price;
  const [clientSecret, setClientSecret] = useState('');

  const createPayment = async (newFormData) => {
    try {
      const data = await api.createPayment(newFormData);
      console.log(data);
      setClientSecret(data.data.clientSecret)
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    console.log(price)
    // Create a Checkout Session as soon as the page loads

    /*
    fetch("https://localhost:8080/create-checkout-session", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ price: price })
    })
      .then((res) => res.json())
    //.then((data) => setClientSecret(data.clientSecret));

    axios.post("https://localhost:8080/create-checkout-session", JSON.stringify({ price: price }), {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },

    })
      .then((response) => {
        setClientSecret(response.data.clientSecret)
      })
      .catch((error) => {
        console.log(error);
      })


      */
    let axiosData = {
      body: JSON.stringify({ price: price }),
      headers: {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }
    };

    createPayment(axiosData)

  }, []);

  return (

    <>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </>

  )
}

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/client" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    )
  }

  return null;
}

const CreateCheckoutSession = (props) => {
  const price = props.price
  return (
    <div>
      <CheckoutForm price={price} />
      {/*<Return /> */}
    </div>
  )
}

export default CreateCheckoutSession;