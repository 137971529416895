import * as actionType from '../constants/actionTypes';

const planReducer = (state = { planData: null, paymentLink: null }, action) => {
  switch (action.type) {
    case actionType.PLANS_GET:
      return { ...state, planData: action.data, loading: false, errors: null };
    case actionType.PLANS_PAYMENT_LINK_GET:
      return { ...state, paymentLink: action.data, loading: false, errors: null };
    default:
      return state;
  }
};

export default planReducer;