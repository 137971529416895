import * as React from 'react';
import { Paper, Typography } from '@mui/material';
import {
    createTheme,
    responsiveFontSizes,
    ThemeProvider,

} from '@mui/material/styles';
import { styled } from '@mui/material/styles';
const Item = styled(Paper)(({ theme }) => ({
    
    textAlign: 'center',
  
    backgroundColor: '#aec6cf',
    ...theme.typography.body2,
    
}));
let theme = createTheme();
export default function Article() {
    return (

        <ThemeProvider theme={theme}>
            <Item sx={{display: 'flex', alignItems: 'center', p: 2, borderRadius: 4, width: "100$"}} >
                <Typography variant="h6" gutterBottom>
                    Interesting Article about Brand Buddy to be updated monthly
                </Typography>
            </Item>
        </ThemeProvider>
    );
}