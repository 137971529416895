import * as actionType from "../constants/actionTypes";

const createAccountDataReducer = (
  state = {
    createAccountData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber:"",
      isAgreeTerms: false,
      isNews: false,
      isValidateEmail: false,
      isPassword: false,
      experiencePhoto: null,
      menu1:"",
      menu2:"",
      menu3:"",
      menu4:"",
      URL1:"",
      URL2:"",
      URL3:"",
      URL4:"",
      RPM_url: "",
      avatar: ""
    },
  },
  action
) => {

  switch (action.type) {
    case actionType.CREATE_ACCOUNT_DATA:
      return {
        ...state,
        createAccountData: action.data,
      };
    case actionType.EMAIL_EXISTS:
      return {
         ...state,
         emailExistMessage: action.exists,
        };
    default:
      return state;
  }

};

export default createAccountDataReducer;
