import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { Box, Button, TextField, Typography, Stack, InputLabel, MenuItem, FormControl, Select, Container } from "@mui/material";

var a;
const AudioPlay = (url) => {

    const customEditData = useSelector((state) => state.customEditState.customEditData);
    const voiceUrl = useSelector((state) => state.voiceUrl);
    const [buttonName, setButtonName] = useState("Play");
    const [disabled, setDisabled] = useState(false);
    const [audio, setAudio] = useState();

    useEffect(() => {
        if (a) {
            a.pause();
            a = null;
            //setButtonName("Play");
        }
        if (audio) {
            setTimeout(() => {
                a = new Audio(audio);
                console.log(a)
                a.onloadstart = () => {
                    console.log('loadstart')
                    // setButtonName("Empty");
                };
                a.ondurationchange = () => {
                    console.log('durationchange')
                };
                a.onloadedmetadata = () => {
                    console.log('loadedmetadata')
                };
                a.onloadeddata = () => {
                    console.log('loadeddata')
                };
                a.onprogress = () => {
                    console.log('progress')
                    //setButtonName("Loading");
                };
                a.oncanplay = () => {
                    setDisabled(true)
                    setButtonName("Play");
                    console.log('canplay')
                };
                a.oncanplaythrough = () => {

                    console.log('canplaythrough')
                };
                a.onended = () => {
                    setButtonName("Play");
                    console.log('onended')
                };
            }, 1000);

        }
    }, [audio]);

    useEffect(() => {
        console.log(customEditData.idVoice)
        addFile2(customEditData.idVoice)
    }, [customEditData.idVoice]);

    useEffect(() => {
        console.log(voiceUrl)
        if (!voiceUrl.isOpen) setDisabled(false)
    }, [voiceUrl]);


    useEffect(() => { addFile2(url) }, [])

    const handleClick = () => {
        if (buttonName === "Play") {
            a.play();
            setButtonName("Pause");
        } else {
            a.pause();
            setButtonName("Play");
        }
    };

    const addFile = (e) => {
        if (e.target.files[0]) {
            setAudio(URL.createObjectURL(e.target.files[0]));
            console.log(e.target.files[0])
        }
    };


    const addFile2 = (e) => {
        setAudio(`https://s3.eu-west-2.amazonaws.com/brandbuddyar/${e}.mp3`);
    };
    return (
        <div>
            <Button disabled={!disabled} variant="contained" onClick={handleClick} color="warning">{buttonName}</Button>
        </div>
    );
};

export default AudioPlay;
