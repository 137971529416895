import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { CREATE_ACCOUNT_DATA } from '../../Redux/constants/actionTypes';

import { useDispatch, useSelector } from "react-redux";
const texts = [{ name: "isAgreeTerms", text: 'I agree to the Terms and Service and confirm I have read the Privacy Policy' },
{ name: "isNews", text: 'I would like to receive the latest news and updates regarding Brand Buddy' }];

export default function AuthRadioButtons() {
    const { createAccountData } = useSelector((state) => state.createAccountDataState)
    const [form, setForm] = useState(createAccountData);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        e.preventDefault()
        //console.log(form)
        if (e.target.name == "isAgreeTerms") console.log(e.target.name, e.target.checked);
        if (e.target.name == "isNews") console.log(e.target.name, e.target.checked);
        setForm({ ...createAccountData, [e.target.name]: e.target.checked });
    }
    useEffect(() => {
        //console.log(form)
    }, [])
    useEffect(() => {
        //console.log(form);
        dispatch({ type: CREATE_ACCOUNT_DATA, data: form });
    }, [form]);
    return (
        <FormGroup>

            {texts.map((label, index) => {
                return (
                    <FormControlLabel key={index} required control={<Checkbox name={label.name} onChange={handleChange} />} label={label.text} />)
            })
            }
        </FormGroup>
    );
}