import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from 'i18next-browser-languagedetector';

const URL = "https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/"

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            'Greeting': `${URL}74e89ac0-bc6f-454d-8e58-f72ea3d9e358.mp3`,
            'Greeting_part2': `${URL}118ec6d6-7c58-400b-b720-2069cb2005fa.mp3`,
            'menuRightTimeBegin': 23000,
            'menuRightTimeEnd': 24000,
            'buttonAugieBegin': 18000,
            'buttonAugieEnd': 21000,
            'Deck': 'Deck',
            'Deck Audio': `${URL}4798f885-674b-4c50-945e-031e0a593298.mp3`,
            'Deck Marks': `47670baf-5a66-49de-b127-bbf4e97e1595`,
            /*'Deck URL': `https://brandbuddyariyp.com/augie-assets/LED.pdf`,*/
            'Deck URL': `https://docs.google.com/presentation/d/1q_jBzqGgEODklR8Hnsf0Qva409nbZ8VEvW8n07oKqXM/present#slide=id.p1`,
            'WHO WE ARE': 'WHO WE ARE',
            'WHO WE ARE Audio': `${URL}7dcf1e38-f72e-4384-9410-f7b2729219c9.mp3`,
            'WHO WE ARE Marks': `7dcf1e38-f72e-4384-9410-f7b2729219c9`,
            'WHO WE ARE URL': `https://www.brandbuddyar.com/who-we-are/`,
            'MAGIC MIRROR': 'MAGIC MIRROR',
            'MAGIC MIRROR Audio': `${URL}7286e07c-adc7-4bcf-b64b-59cf31f789de.mp3`,
            'MAGIC MIRROR Marks': `7286e07c-adc7-4bcf-b64b-59cf31f789de`,
            'MAGIC MIRROR URL': `https://www.brandbuddyar.com/what-we-do/`,
            'AR IYP': 'AR IYP',
            'AR IYP Audio': `${URL}0b4afd50-361b-49ca-b643-a7cd5518366a.mp3`,
            'AR IYP Marks': `0b4afd50-361b-49ca-b643-a7cd5518366a`,
            'AR IYP URL': `https://www.brandbuddyar.com/ar-iyp/`,
            'EXR CODES': 'EXR CODES',
            'EXR CODES Audio': `${URL}255b38c6-c7ed-43d0-8cfe-6ac8c6142741.mp3`,
            'EXR CODES Marks': `255b38c6-c7ed-43d0-8cfe-6ac8c6142741`,
            'EXR CODES URL': `https://www.brandbuddyar.com/e-xr-codes/`,
            'PERK LABS': 'PERK LABS',
            'PERK LABS Audio': `${URL}914adf0d-f6fa-4928-89ca-ba739cd32907.mp3`,
            'PERK LABS Marks': `914adf0d-f6fa-4928-89ca-ba739cd32907`,
            'PERK LABS URL': `https://www.brandbuddyar.com/the-perk-lab/`,
            'CONTACT US': 'CONTACT US',
            'CONTACT US Audio': `${URL}21ab8c4b-74a5-4734-a040-f0284bbe261e.mp3`,
            'CONTACT US Marks': `21ab8c4b-74a5-4734-a040-f0284bbe261e`,
            'CONTACT US URL': `https://www.brandbuddyar.com/contact-us/`,
        }
    },
    ru: {
        translation: {
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/1a824f2b-ddc5-4632-bd19-fc4e624532ae.mp3',
            'menuRightTimeBegin': 17000,
            'menuRightTimeEnd': 18000,
            'buttonAugieBegin': 31000,
            'buttonAugieEnd': 33000,
            'Deck' : 'План',
            'Deck Audio': `${URL}3f8e5fbc-902b-4354-9301-c7f81c959ff5.mp3`,
            'Deck Marks': `3f8e5fbc-902b-4354-9301-c7f81c959ff5`,
            'Deck URL': `https://brandbuddyariyp.com/augie-assets/LED.pdf`,
            'WHO WE ARE': 'Кто мы есть', 
            'WHO WE ARE Audio': `${URL}4bf30280-75a7-4ea7-9130-0fd6752ab88a.mp3`,
            'WHO WE ARE Marks': `4bf30280-75a7-4ea7-9130-0fd6752ab88a`,
            'WHO WE ARE URL': `https://www.brandbuddyar.com/who-we-are/`,
            'MAGIC MIRROR': 'Волшебное зеркало',
            'MAGIC MIRROR Audio': `${URL}0c83aa05-94c4-4387-a023-cd662050f8d0.mp3`,
            'MAGIC MIRROR Marks': `0c83aa05-94c4-4387-a023-cd662050f8d0`, 
            'MAGIC MIRROR URL': `https://www.brandbuddyar.com/what-we-do/`,
            'AR IYP': 'AR IYP',
            'AR IYP Audio': `${URL}e89f81b6-635a-4625-8385-22ff7b5eed51.mp3`,
            'AR IYP Marks': `e89f81b6-635a-4625-8385-22ff7b5eed51`,
            'AR IYP URL': `https://www.brandbuddyar.com/ar-iyp/`,
            'EXR CODES': 'EXR коды',
            'EXR CODES Audio': `${URL}255b38c6-c7ed-43d0-8cfe-6ac8c6142741.mp3`,
            'EXR CODES Marks': `255b38c6-c7ed-43d0-8cfe-6ac8c6142741`,
            'EXR CODES URL': `https://www.brandbuddyar.com/e-xr-codes/`,
            'PERK LABS': 'PERK Лаборатории',
            'PERK LABS Audio': `${URL}914adf0d-f6fa-4928-89ca-ba739cd32907.mp3`,
            'PERK LABS Marks': `914adf0d-f6fa-4928-89ca-ba739cd32907`,
            'PERK LABS URL': `https://www.brandbuddyar.com/the-perk-lab/`,
            'CONTACT US': 'Связаться с нами',
            'CONTACT US Audio': `${URL}21ab8c4b-74a5-4734-a040-f0284bbe261e.mp3`,
            'CONTACT US Marks': `21ab8c4b-74a5-4734-a040-f0284bbe261e`,
            'CONTACT US URL': `https://www.brandbuddyar.com/contact-us/`,

        }
    },
    ar: {
        translation: {
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/f7486701-78c7-4f45-b987-13cc83224921.mp3',
            'Emergency': 'طارئ',
            'Emergency Audio': `${URL}9d819c35-f8d0-4281-a8a6-ebf155bb66a3.mp3`,
            'Emergency Marks': `9d819c35-f8d0-4281-a8a6-ebf155bb66a3`,
            'Police': 'شرطة',
            'Police Audio': `${URL}e02d374a-8c2c-4aa2-8e8e-6e577c5571b8.mp3`,
            'Police Marks': `e02d374a-8c2c-4aa2-8e8e-6e577c5571b8`,
            'Ambulance': 'سياره اسعاف',
            'Ambulance Audio': `${URL}3fff04ac-7881-48b8-9a39-b35c97e2b401.mp3`,
            'Ambulance Marks': `3fff04ac-7881-48b8-9a39-b35c97e2b401`,
            'Fire Department': 'قسم الأطفاء',
            'Fire Department Audio': `${URL}39d756bc-1ba4-4081-9b32-65036e670eeb.mp3`,
            'Fire Department Marks': `39d756bc-1ba4-4081-9b32-65036e670eeb`,
            'Deck': 'الثقافة',
            'Deck Audio': `${URL}86e93cfc-00b0-4dd7-a02b-5a909dbc240c.mp3`,
            'Deck Marks': `86e93cfc-00b0-4dd7-a02b-5a909dbc240c`,
            'WHO WE ARE': 'الرياضة', 
            'WHO WE ARE Audio': `${URL}32f19c73-f32b-477d-bf95-92513eab61c0.mp3`,
            'WHO WE ARE Marks': `32f19c73-f32b-477d-bf95-92513eab61c0`,
            'MAGIC MIRROR': 'وضع الأطفال', 
            'MAGIC MIRROR Audio': `${URL}8b50916b-fa83-4d1f-983d-093b081cb72b.mp3`,
            'MAGIC MIRROR Marks': `8b50916b-fa83-4d1f-983d-093b081cb72b`,
            'AR IYP': 'وضع الكبار',
            'AR IYP Audio': `${URL}8624816b-2a8a-4cfa-bbc5-5623495a748e.mp3`,
            'AR IYP Marks': `8624816b-2a8a-4cfa-bbc5-5623495a748e`,
        }
    },
    fr: {
        translation: {
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/5847a6cb-5f25-4451-b12a-222877fe4bb2.mp3',
            'Emergency': 'Urgence',
            'Emergency Audio': `${URL}81ffc0ac-0c5a-4d16-99c3-59371f5ecc90.mp3`,
            'Emergency Marks': `81ffc0ac-0c5a-4d16-99c3-59371f5ecc90`,
            'Police': 'Police',
            'Police Audio': `${URL}f259cf2c-1d57-4456-a82e-3d4060785801.mp3`,
            'Police Marks': `f259cf2c-1d57-4456-a82e-3d4060785801`,
            'Ambulance': 'Ambulance',
            'Ambulance Audio': `${URL}951e82c3-1946-4abb-80dc-9c389adb186f.mp3`,
            'Ambulance Marks': `951e82c3-1946-4abb-80dc-9c389adb186f`,
            'Fire Department': 'Sapeurs-pompiers',
            'Fire Department Audio': `${URL}cb927db4-c42a-49ce-8ea4-6d3d77f7271a.mp3`,
            'Fire Department Marks': `cb927db4-c42a-49ce-8ea4-6d3d77f7271a`,
            'Deck ': 'Deck',
            'Deck Audio': `${URL}d9b8d825-15b3-4510-a55b-23ecc4d78314.mp3`,
            'Deck Marks': `d9b8d825-15b3-4510-a55b-23ecc4d78314`,
            'WHO WE ARE': 'WHO WE AREs', 
            'WHO WE ARE Audio': `${URL}a6482caf-574c-41b0-87c4-df2f300471eb.mp3`,
            'WHO WE ARE Marks': `a6482caf-574c-41b0-87c4-df2f300471eb`,
            'MAGIC MIRROR': 'Mode Enfants', 
            'MAGIC MIRROR Audio': `${URL}f4aa417b-ff14-4772-bb5b-fbc4f27f3d86.mp3`,
            'MAGIC MIRROR Marks': `f4aa417b-ff14-4772-bb5b-fbc4f27f3d86`,
            'AR IYP': 'Mode adulte',
            'AR IYP Audio': `${URL}e0247f80-58bb-40de-8142-c2169bd77305.mp3`,
            'AR IYP Marks': `e0247f80-58bb-40de-8142-c2169bd77305`,
        }
    },
    es: {
        translation: {
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/99f019cf-f31b-4ab7-9348-43c7d0e60f7a.mp3',
            'Emergency': 'Emergencia',
            'Emergency Audio': `${URL}8605a547-f5e9-4d2d-a243-35d2d0df2f51.mp3`,
            'Emergency Marks': `8605a547-f5e9-4d2d-a243-35d2d0df2f51`,
            'Police': 'Policía',
            'Police Audio': `${URL}2cbc6e01-c3d5-45f8-aff9-009130a79f5b.mp3`,
            'Police Marks': `2cbc6e01-c3d5-45f8-aff9-009130a79f5b`,
            'Ambulance': 'Ambulancia',
            'Ambulance Audio': `${URL}968f6d8f-0ea2-4d4a-948c-38cb2a2e9dbd.mp3`,
            'Ambulance Marks': `968f6d8f-0ea2-4d4a-948c-38cb2a2e9dbd`,
            'Fire Department': 'Departamento de Bomberos',
            'Fire Department Audio': `${URL}a2d661ad-648d-4329-8f3a-ca28f1976d22.mp3`,
            'Fire Department Marks': `a2d661ad-648d-4329-8f3a-ca28f1976d22`,
            'Deck ': 'Cultura',
            'Deck Audio': `${URL}eeecc918-0e45-4f2c-b108-5d286c07b1d4.mp3`,
            'Deck Marks': `eeecc918-0e45-4f2c-b108-5d286c07b1d4`,
            'WHO WE ARE': 'Deporte', 
            'WHO WE ARE Audio': `${URL}9acd7d5f-678f-4e14-86b2-65d34cbfdff4.mp3`,
            'WHO WE ARE Marks': `9acd7d5f-678f-4e14-86b2-65d34cbfdff4`,
            'MAGIC MIRROR': 'Modo para niños',
            'MAGIC MIRROR Audio': `${URL}7262186b-4f7c-4ab8-af82-d0ac9572fb9c.mp3`,
            'MAGIC MIRROR Marks': `7262186b-4f7c-4ab8-af82-d0ac9572fb9c`,
            'AR IYP': 'Modo adulto',
            'AR IYP Audio': `${URL}6e1d6047-04ee-4997-a90b-2c023b91fa8b.mp3`,
            'AR IYP Marks': `6e1d6047-04ee-4997-a90b-2c023b91fa8b`,
        }
    },
    pt: {
        translation: {
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/9d828850-37b3-4b0f-a16e-1e89be25ecb2.mp3',
            'Emergency': 'Emergência',
            'Emergency Audio': `${URL}43e52537-d49b-423f-9d98-e3beb052f85f.mp3`,
            'Emergency Marks': `43e52537-d49b-423f-9d98-e3beb052f85f`,
            'Police': 'Polícia',
            'Police Audio': `${URL}8511217c-1242-410f-a10e-b062ebff2eb4.mp3`,
            'Police Marks': `8511217c-1242-410f-a10e-b062ebff2eb4`,
            'Ambulance': 'Ambulância',
            'Ambulance Audio': `${URL}10f7ab72-f740-4f72-93c1-8657d546750f.mp3`,
            'Ambulance Marks': `10f7ab72-f740-4f72-93c1-8657d546750f`,
            'Fire Department': 'Corpo de Bombeiros',
            'Fire Department Audio': `${URL}4ed29ef2-651c-4555-9d0e-885adcf501dd.mp3`,
            'Fire Department Marks': `4ed29ef2-651c-4555-9d0e-885adcf501dd`,
            'Deck ': 'Cultura',
            'Deck Audio': `${URL}bb4d20d7-b428-4970-a489-72e264599050.mp3`,
            'Deck Marks': `bb4d20d7-b428-4970-a489-72e264599050`,
            'WHO WE ARE': 'EWHO WE AREe', 
            'WHO WE ARE Audio': `${URL}91638be5-ac2b-4fd3-9fa4-516c0142b632.mp3`,
            'WHO WE ARE Marks': `91638be5-ac2b-4fd3-9fa4-516c0142b632`,
            'MAGIC MIRROR': 'Modo Infantil', 
            'MAGIC MIRROR Audio': `${URL}adb04753-2b71-4191-a17e-76def2af29e2.mp3`,
            'MAGIC MIRROR Marks': `adb04753-2b71-4191-a17e-76def2af29e2`,
            'AR IYP': 'Modo adulto',
            'AR IYP Audio': `${URL}5b4fc9ab-ea9f-4cd9-96c5-80ba35ec9836.mp3`,
            'AR IYP Marks': `5b4fc9ab-ea9f-4cd9-96c5-80ba35ec9836`,
        }
    },
    de: {
        translation: {
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/689d3474-b1aa-4791-868f-e004ea6f1b48.mp3',
            'Emergency': 'Notfall',
            'Emergency Audio': `${URL}127531aa-7dc1-478d-a95b-27fb98d4788e.mp3`,
            'Emergency Marks': `127531aa-7dc1-478d-a95b-27fb98d4788e`,
            'Police': 'Polizei',
            'Police Audio': `${URL}ed1060f0-971c-4bec-9ab8-a5291d0259e9.mp3`,
            'Police Marks': `ed1060f0-971c-4bec-9ab8-a5291d0259e9`,
            'Ambulance': 'Krankenwagen',
            'Ambulance Audio': `${URL}6ad8eee5-daa8-475f-a53a-7d92702a98f2.mp3`,
            'Ambulance Marks': `6ad8eee5-daa8-475f-a53a-7d92702a98f2`,
            'Fire Department': 'Feuerwehr',
            'Fire Department Audio': `${URL}b74db138-6dea-4c95-af61-dd13282cfe19.mp3`,
            'Fire Department Marks': `b74db138-6dea-4c95-af61-dd13282cfe19`,
            'Deck ': 'Kultur',
            'Deck Audio': `${URL}5f0ba81d-d3d0-40cb-abe0-51515ca364f7.mp3`,
            'Deck Marks': `5f0ba81d-d3d0-40cb-abe0-51515ca364f7`,
            'WHO WE ARE': 'WHO WE ARE', 
            'WHO WE ARE Audio': `${URL}7fd96791-1934-4603-87e8-c0510ebe4bff.mp3`,
            'WHO WE ARE Marks': `7fd96791-1934-4603-87e8-c0510ebe4bff`,
            'MAGIC MIRROR': 'Kindermodus', 
            'MAGIC MIRROR Audio': `${URL}3a6cf7b2-fd18-4396-9aca-e175af0378e0.mp3`,
            'MAGIC MIRROR Marks': `3a6cf7b2-fd18-4396-9aca-e175af0378e0`,
            'AR IYP': 'Erwachsenenmodus',
            'AR IYP Audio': `${URL}5123c000-a8d7-4789-a82c-801fca2178a7.mp3`,
            'AR IYP Marks': `5123c000-a8d7-4789-a82c-801fca2178a7`,
        }
    },
    it: {
        translation: {
            'Boarding Gates': "Cancelli d'imbarco",
            'Boarding Gates Audio': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/b3a5d7ce-73d6-45ca-aa71-7d895d85b9c3.mp3',
            'PasWHO WE ARE Control': 'Controllo del passaporto',
            'PasWHO WE ARE Control Audio': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/550f7f19-e2aa-4197-9473-fd310725c5e8.mp3',
            'Check-in Desk': 'Banco del check-in',
            'Check-in Desk Audio': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/b10e4287-a13e-452d-9faa-9bb19cf32ce7.mp3',
            'Toilets': 'Servizi igienici',
            'Toilets Audio': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/40104654-34ca-4a0d-af52-a46136302902.mp3',
            'Restaurants': 'Ristoranti',
            'Restaurants Audio': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/8c239dfc-2d9c-44d8-afd3-3398a67b7194mp3',
            'Help': 'Aiuto',
            'Help Audio': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/85fc520f-4a9f-45a1-9a76-b5c4537f189a.mp3',
            'Greeting': 'https://s3-eu-west-2.amazonaws.com/amazon-polly-3rock/061c7bfa-7aa2-42c5-bd53-89a6fe3fba83.mp3',
            'Emergency': 'Emergenza',
            'Emergency Audio': `${URL}02824a47-80c1-49ca-a485-31259e9e9386.mp3`,
            'Emergency Marks': `02824a47-80c1-49ca-a485-31259e9e9386`,
            'Police': 'Polizia',
            'Police Audio': `${URL}0a9fc0a5-d3b6-4605-a864-fa13e0792cf4.mp3`,
            'Police Marks': `0a9fc0a5-d3b6-4605-a864-fa13e0792cf4`,
            'Ambulance': 'Ambulanza',
            'Ambulance Audio': `${URL}8807df5e-bd3b-4f24-9ca4-67a8bdc50022.mp3`,
            'Ambulance Marks': `8807df5e-bd3b-4f24-9ca4-67a8bdc50022`,
            'Fire Department': 'Vigili del fuoco',
            'Fire Department Audio': `${URL}33e3ff34-8465-4243-920b-19f3cc4709fc.mp3`,
            'Fire Department Marks': `33e3ff34-8465-4243-920b-19f3cc4709fc`,
            'Cultura': 'Cultura',
            'Deck Audio': `${URL}5648f2f4-9249-482c-bd9f-554d8c80207c.mp3`,
            'Deck Marks': `5648f2f4-9249-482c-bd9f-554d8c80207c`,
            'WHO WE ARE': 'WHO WE ARE',
            'WHO WE ARE Audio': `${URL}5da505ef-ac9c-4d3c-afef-be8e82cf078a.mp3`,
            'WHO WE ARE Marks': `5da505ef-ac9c-4d3c-afef-be8e82cf078a`,
            'MAGIC MIRROR': 'Modalità bambini',
            'MAGIC MIRROR Audio': `${URL}f8629f9e-7f34-43c3-80e3-6008b51553e4.mp3`,
            'MAGIC MIRROR Marks': `f8629f9e-7f34-43c3-80e3-6008b51553e4`,
            'AR IYP': 'Modalità adulto',
            'AR IYP Audio': `${URL}ca65ba04-e65d-489a-aacc-139a94293025.mp3`,
            'AR IYP Marks': `ca65ba04-e65d-489a-aacc-139a94293025`,
        }
    },
};

i18n
    .use(detector) // passes i18n down to react-i18next
    .use(initReactI18next)
    .init({
        resources,
        //lng: localStorage.getItem('language') || 'en',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        load: 'languageOnly',
    });

if(navigator.language) i18n.changeLanguage(navigator.language);
//i18n.changeLanguage("it");

export default i18n;