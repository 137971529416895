import { combineReducers } from "redux";

import langReducer from "./Redux/reducers/langReducer";
import menuRightReducer from "./Redux/reducers/menuRightReducer";
import userReducer from './Redux/reducers/userReducer'
import menuLeftReducer from "./Redux/reducers/menuLeftReducer";
import greetingAudioReducer from "./Redux/reducers/greetingAudioReducer";
import buttonAugieReducer from "./Redux/reducers/buttonAugieReducer";
import augieReducer from "./Redux/reducers/augieReducer";
import greetingAudioFinishedReducer from "./Redux/reducers/greetingAudioFinishedReducer";
import voiceUrlReducer from "./Redux/reducers/voiceUrlReducer";
import runARModeReducer from "./Redux/reducers/ARModeReducer";
import modelsLoaderReducer from "./Redux/reducers/modelsLoaderReducer";
import auth from "./Redux/reducers/auth";
import plan from "./Redux/reducers/plan";
import customEditReducer from "./Redux/reducers/custom";
import experienceReducer from "./Redux/reducers/experience";
import createAccountDataReducer from "./Redux/reducers/createAccountData";

const rootReducer = combineReducers({
  lang: langReducer,
  settingsRight: menuRightReducer,
  settingsLeft: menuLeftReducer,
  greetingAudio: greetingAudioReducer,
  buttonAugie: buttonAugieReducer,
  modelAugie: augieReducer,
  greetingAudioFinishedEvent: greetingAudioFinishedReducer,
  voiceUrl: voiceUrlReducer,
  ARMode: runARModeReducer,
  modelsAreLoaded: modelsLoaderReducer,
  authState: auth,
  customEditState: customEditReducer,
  experienceState: experienceReducer,
  createAccountDataState: createAccountDataReducer,
  userReducer:userReducer,
  planState : plan
});

export default rootReducer;
