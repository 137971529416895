import { PLANS_GET, PLANS_PAYMENT_LINK_GET } from '../constants/actionTypes';
import * as api from '../../api/index.js';

export const getPlans = () => async (dispatch) => {
    try {
      const { data } = await api.fetchPlans();
  
      dispatch({ type: PLANS_GET, data });
    } catch (error) {
      console.log(error);
    }
};

export const getPlanPaymentLink = (id) => async (dispatch) => {
  try {
    let payload = {
      priceId : id
    }
    const { data } = await api.fetchPlanPaymentLink(payload);

    dispatch({ type: PLANS_PAYMENT_LINK_GET, data });

    window.location.href = data.paymentLink;
  } catch (error) {
    console.log(error);
  }
}