import * as actionType from "../constants/actionTypes";

const customEditReducer = (
  state = {
    customEditData: {
      experience: "",
      firstName: "",
      lastName: "",
      tel: "",
      email: "",
      date: "",
      idVoice: "",
      textVoice: 
        `Hi everyone!`,
      idExperience: "",
      experiencePhoto: null,
      menu1: "Home",
      menu2: "Specs",
      menu3: "More Info",
      menu4: "Contact Us",
      URL1: "https://",
      URL2: "https://",
      URL3: "https://",
      URL4: "https://",
      RPM_url: "",
      avatar: "RPM",
    },
  },
  action
) => {
  switch (action.type) {
    case actionType.CUSTOM_EDIT:
      //console.log(action.data)
      return {
        ...state,
        customEditData: action.data,
      };
    default:
      return state;
  }
};

export default customEditReducer;
