
export const voices = [{
    language: "Arabic", code: "arb", name: "Zeina", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandartVoice: "Yes"
},
{
    language: "Arabic (Gulf)", code: "ar-AE", name: "Hala", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandartVoice: "No"
},
{
    language: "Arabic (Gulf)", code: "ar-AE", name: "Zayd", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandartVoice: "No"
},
{
    language: "Dutch (Belgian)", code: "nl-BE", name: "Lisa", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Catalan", code: "ca-ES", name: "Arlet", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Chinese (Cantonese)", code: "yue-CN", name: "Hiujin", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Chinese (Mandarin)", code: "cmn-CN", name: "Zhiyu", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Danish", code: "da-DK", name: "Naja", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Danish", code: "da-DK", name: "Mads", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Danish", code: "da-DK", name: "Sofie", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Dutch", code: "nl-NL", name: "Laura", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Dutch", code: "nl-NL", name: "Lotte", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Dutch", code: "nl-NL", name: "Ruben", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "English (Australian)", code: "en-AU", name: "Nicole", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "English (Australian)", code: "en-AU", name: "Olivia", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (Australian)", code: "en-AU", name: "Russell", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "English (British)", code: "en-GB", name: "Amy", gender: "Female", GenerativeVoice: "Yes", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (British)", code: "en-GB", name: "Emma", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (British)", code: "en-GB", name: "Brian", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (British)", code: "en-GB", name: "Arthur", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (Indian)", code: "n-IN", name: "Aditi", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "English (Indian)", code: "n-IN", name: "Raveena", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "English (Indian)", code: "n-IN", name: "Kajal", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (Ireland)", code: "en-IE", name: "Niamh", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (New Zealand)", code: "en-NZ", name: "Aria", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (South African)", code: "en-ZA", name: "Ayanda", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Danielle", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Gregory", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Ivy", gender: "Female(child)", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (US)", code: "en-US", name: "Joanna", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (US)", code: "en-US", name: "Kendra", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (US)", code: "en-US", name: "Kimberly", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (US)", code: "en-US", name: "Salli", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (US)", code: "en-US", name: "Joey", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "English (US)", code: "en-US", name: "Justin", gender: "Male(child)", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Kevin", gender: "Male(child)", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Matthew", gender: "Male", GenerativeVoice: "Yes", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Ruth", gender: "Female", GenerativeVoice: "Yes", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (US)", code: "en-US", name: "Stephen", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "English (Welsh)", code: "en-GB-WLS", name: "Geraint", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Finnish", code: "fi-FI", name: "Suvi", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "French", code: "fr-FR", name: "Celine", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "French", code: "fr-FR", name: "Lea", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
}, {
    language: "French", code: "fr-FR", name: "Mathieu", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "French", code: "fr-FR", name: "Remi", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "French (Belgian)", code: "fr-BE", name: "Isabelle", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "French (Canadian)", code: "fr-CA", name: "Chantal", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "French (Canadian)", code: "fr-CA", name: "Gabrielle", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "French (Canadian)", code: "fr-CA", name: "Liam", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "German", code: "de-DE", name: "Marlene", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "German", code: "de-DE", name: "Vicki", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "German", code: "de-DE", name: "Hans", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "German", code: "de-DE", name: "Daniel", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "German (Austrian)", code: "de-AT", name: "Hannah", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Hindi", code: "hi-IN", name: "Aditi", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Hindi", code: "dhi-IN", name: "Kajal", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Icelandic", code: "is-IS", name: "Dora", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Icelandic", code: "is-IS", name: "Karl", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Italian", code: "it-IT", name: "Carla", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Italian", code: "it-IT", name: "Bianca", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Italian", code: "it-IT", name: "Giorgio", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Italian", code: "it-IT", name: "Adriano", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Japanese", code: "ja-JP", name: "Mizuki", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Japanese", code: "ja-JP", name: "Takumi", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Japanese", code: "ja-JP", name: "Kazuha", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Japanese", code: "ja-JP", name: "Tomoko", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Korean", code: "ko-KR", name: "Seoyeon", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Norwegian", code: "nb-NO", name: "Liv", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Norwegian", code: "nb-NO", name: "Ida", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Polish", code: "pl-PL", name: "Ewa", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Polish", code: "pl-PL", name: "Maja", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Polish", code: "pl-PL", name: "Jacek", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Polish", code: "pl-PL", name: "Jan", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Polish", code: "pl-PL", name: "Ola", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Portuguese (Brazilian)", code: "pt-BR", name: "Camila", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Portuguese (Brazilian)", code: "pt-BR", name: "Vitoria", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Portuguese (Brazilian)", code: "pt-BR", name: "Ricardo", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Portuguese (Brazilian)", code: "pt-BR", name: "Thiago", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Portuguese (European)", code: "pt-PT", name: "Ines", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Portuguese (European)", code: "pt-PT", name: "Cristiano", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Romanian", code: "ro-RO", name: "Carmen", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Russian", code: "ru-RU", name: "Tatyana", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Russian", code: "ru-RU", name: "Maxim", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Spanish (European)", code: "es-ES", name: "Conchita", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Spanish (European)", code: "es-ES", name: "Lucia", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Spanish (European)", code: "es-ES", name: "Enrique", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Spanish (European)", code: "es-ES", name: "Sergio", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Spanish (Mexican)", code: "es-MX", name: "Mia", gender: "a", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Spanish (Mexican)", code: "es-MX", name: "Andres", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Spanish (US)", code: "es-US", name: "Lupe", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "Yes"
},
{
    language: "Spanish (US)", code: "es-US", name: "Penelope", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Spanish (US)", code: "es-US", name: "Miguel", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Spanish (US)", code: "es-US", name: "Pedro", gender: "Male", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Swedish", code: "sv-SE", name: "Astrid", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Swedish", code: "sv-SE", name: "Elin", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Turkish", code: "tr-TR", name: "Filiz", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
},
{
    language: "Turkish", code: "tr-TR", name: "Burcu", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "Yes", StandardVoice: "No"
},
{
    language: "Welsh", code: "cy-GB", name: "Gwyneth", gender: "Female", GenerativeVoice: "No", LongFormVoice: "No", NeuralVoice: "No", StandardVoice: "Yes"
}

]