import { createStyled } from '@mui/system';

export default createStyled((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "rgb(232, 241, 250)"
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "rgb(250, 232, 241)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "rgb(232, 241, 250)"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "rgb(250, 241, 232)"
    }
  }
}));
