export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const CREATE_ACCOUNT_DATA = 'CREATE_ACCOUNT_DATA';
export const AUTH_NEXT = 'AUTH_NEXT';
export const LOGOUT = 'LOGOUT';
export const CUSTOM_EDIT = 'CUSTOM_EDIT';
export const EXPERIENCE_PUT = 'EXPERIENCE_PUT';
export const EXPERIENCE_GET = 'EXPERIENCE_GET';
export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const EMAIL_EXISTS = 'EMAIL_EXISTS'


export const VERIFY_OPT_REQUEST = 'VERIFY_OPT_REQUEST';
export const VERIFY_OPT_SUCCESS = 'VERIFY_OPT_SUCCESS';
export const VERIFY_OPT_FAILURE = 'VERIFY_OPT_FAILURE';
export const VERIFY_OPT_STATUS = 'VERIFY_OPT_STATUS';
export const PLANS_GET = 'PLANS_GET';
export const PLANS_PAYMENT_LINK_GET = 'PLANS_PAYMENT_LINK_GET';
