const modelsLoaderReducer = (isLoaded = false, action) => {
    switch (action.type) {
        case 'MODELS_LOADED':
            console.log('MODELS_LOADED');
            return true;
        case 'MODELS_NOT_LOADED':
            console.log('MODELS_NOT_LOADED');
            return false;
        default:
            return isLoaded;
    }

}
export default modelsLoaderReducer;