const buttonAugieReducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'BUTTON_AUGIE_HIDE':
            console.log('BUTTON_AUGIE_HIDE');
            return false;
        case 'BUTTON_AUGIE_VISIBLE':
            console.log('BUTTON_AUGIE_VISIBLE');
            return true;
        default:
            return isOpen;
    }

}
export default buttonAugieReducer;