import React, { Component } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "qrcode.react";
//import TutorialDataService from "../../../Services/tutorial.service";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

function GenerateQRComponent() {
  const experienceResponse = useSelector((state) => state.experienceState);
  const [idGeneratedExperience, setIdGeneratedExperience] =
  React.useState(null);

  React.useEffect(() => {
    if (experienceResponse.experienceData) {
      console.log(experienceResponse.experienceData.newData.idExperience);
      setIdGeneratedExperience(
        experienceResponse.experienceData.newData.idExperience
      );
    }
  }, [experienceResponse]);

  function downloadQRCode() {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${this.state.salesdeckurl}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <QRCode
      id="qr-gen"
      value={
        `${window.location.href}ar/${idGeneratedExperience}`
          
      }
      size={200}
      level={"H"}
      includeMargin={true}
    />
  );
}

export default GenerateQRComponent;
