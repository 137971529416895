import React from 'react';
import { TextField, Grid, InputAdornment, IconButton } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Input = ({ name, handleChange, label, half, autoFocus, type, handleShowPassword, helperText, defaultValue }) => (

    <TextField
      name={name}
      autoComplete="off"
      onChange={handleChange}
      variant="filled"
      required
      fullWidth
      label={label}
      autoFocus={autoFocus}
      type={type}
      helperText={helperText}
      defaultValue={defaultValue}
      InputProps={name === 'password' ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>
              {type === 'password' ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      } : null}
    />

);

export default Input;
